.AppPageHeader{
    color: black;
    background-color: white;
}
.AppPageHeader button{
    display: inline-block;
    padding: 20px;
}
.AppPageHeader div{
    display: inline-block;
    padding: 20px;
    text-align: center;
}
.AppPageHeader .btn_div button{
    background-color: white;
    border: none;
}
.AppPageHeader .btn_div{
    width: 60px;
    padding: 0;
    margin: 0;
}
.AppPageHeader .ttl_div{
    width: calc(100% - 134px) !important;
    font-size: 20px;
    font-weight: 400;
    line-height: 16px;
    color: black;
    background-color: white;
}